import { filter as _filter } from 'lodash';
import { Direction } from '@lce/intl-types/src/IDirection';
import { IFranchisees } from '@lce/gatsby-theme-multi-site/src/api/FranchiseeId/hooks/useFranchisees';

const franchiseeDirection = (franchisees: IFranchisees[], SelectedFranchiseeId: string | undefined) => {
  const currentFranchisee = _filter(franchisees, (node: any) => node.id === SelectedFranchiseeId)[0];
  const direction: Direction = currentFranchisee?.isRtl ? 'rtl' : 'ltr';
  return direction;
};

export default franchiseeDirection;
