import * as React from 'react';
import { Helmet } from 'react-helmet';

const IePolyfill: React.FC = () => (
  <Helmet>
    {typeof window !== 'undefined' &&
      (window as any).MSInputMethodContext && (document as any).documentMode &&
      <script src="/ie11CustomProperties_v4.0.1.js" />
    }
  </Helmet>
);

export default IePolyfill;
